@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer components {
  .hoverEffect {
    @apply duration-300 cursor-pointer;
  }
  .headerSedenavLi {
    @apply hover:underline underline-offset-2 cursor-pointer text-gray-300 hover:text-white;
  }
}
/* ============= NavLink active Start here ============== */
/* nav .active li {
  color: #fc148c;
  font-weight: 600;
  text-decoration: 1px underline #fc148c;
} */

@media screen and (max-width: 665px) {
  nav .active li {
    color: white;
    font-weight: 600;
    text-decoration: 1px underline white;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
/* ============= NavLink active End here ================ */
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

/* Estilo del checkbox cuando está seleccionado */
input[type="checkbox"]:checked {
  background-color: #fc148c;
  border-color: #fc148c;
}

/* Estilo adicional para mejorar la accesibilidad y enfoque */
input[type="checkbox"]:focus {
  outline: none;
  box-shadow: 0 0 1px #fc148c;
}

/* Importaciones de estilos de Slick Carousel */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';